import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "description": "Build web bots declaratively in TypeScript with the new simple framework called Botmation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <img src="banner/1556x379v2.png" alt="Botmation Crew" width="474" style={{
      "marginBottom": ".5rem"
    }} />
    <p><a parentName="p" {...{
        "href": "https://travis-ci.com/mrWh1te/Botmation"
      }}><img parentName="a" {...{
          "src": "https://travis-ci.com/mrWh1te/Botmation.svg?branch=master",
          "alt": "Build Status"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://codecov.io/gh/mrWh1te/Botmation"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/codecov/c/github/mrWh1te/Botmation/master?label=codecov",
          "alt": "codecov"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://sonarcloud.io/dashboard?id=mrWh1te_Botmation"
      }}><img parentName="a" {...{
          "src": "https://sonarcloud.io/api/project_badges/measure?project=mrWh1te_Botmation&metric=alert_status",
          "alt": "Quality Gate Status"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://david-dm.org/mrWh1te/Botmation"
      }}><img parentName="a" {...{
          "src": "https://david-dm.org/mrWh1te/Botmation/status.svg",
          "alt": ""
        }}></img></a>{`
`}<img parentName="p" {...{
        "src": "https://img.shields.io/github/license/mrWh1te/Botmation",
        "alt": "GitHub"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/mrWh1te/Botmation"
      }}>{`Botmation`}</a>{` is a TypeScript framework for declaratively building bots with `}<a parentName="p" {...{
        "href": "https://github.com/puppeteer/puppeteer"
      }}>{`Puppeteer`}</a>{`. It maximizes code `}<em parentName="p">{`readability`}</em>{`, `}<em parentName="p">{`maintainability`}</em>{` and `}<em parentName="p">{`testability`}</em>{` with a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Function_composition_%28computer_science%29"
      }}>{`compositional design`}</a>{`.`}</p>
    <p>{`These bots can do whatever people do with a web browser and more. They can surf and scrape complex web applications, automate social media, take screenshots, generate website PDF's and much more! 🌊🏄📸`}</p>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul style={{
      "paddingLeft": ".75rem",
      "listStyle": "none"
    }}>
      <li>📚 Get started with Functional Programming</li>
      <li>🤓 Learn only what you need</li>
      <li>🛣️ Follow one simple coding pattern</li>
      <li>⚡️ Write readable, testable & composable code</li>
      <li>🥇 Be confident with 100% Core library test coverage</li>
      <li>🧱 Assemble complex async functionality with ease</li>
      <li>🔥 and much more!</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "/overview"
      }}>{`Start with overview`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      